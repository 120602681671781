import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import '../../styles/css/CreatorAwards.css'
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";
import posed, {PoseGroup} from "react-pose";
import { isObjectEmpty } from '../utils/HelpfulFunction';

const Trans = posed.div({
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

let interval;

class SideBigScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            startAnimating: true,
            timeLeft: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        })
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        })

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true
        });
        this.gameTimingRef = base.bindToState('currentGame/gameTiming', {
            context: this,
            state: 'gameTiming',
        })
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        let serverTimeOffset = 0;
        this.serverTimeOffsetRef = base.listenTo(".info/serverTimeOffset", {
            context: this,
            then(data){
                serverTimeOffset = data;
            }
        })
        this.startQuestionToShowTimerRef = base.listenTo("currentGame/startQuestionToShowTimer", {
            context: this,
            then(data){
                const variables = this.props.variables || {};
                let seconds = variables.questionDisplayLength || 5;
                seconds = parseInt(seconds);
                const startQuestionToShowTimer = data;
                if(!startQuestionToShowTimer || typeof startQuestionToShowTimer === "object"){
                    if(interval){
                        clearInterval(interval);
                    }
                    this.setState({
                        timeLeft: "0:00"
                    })
                    return
                }
                interval = setInterval(() => {
                    const timeLeft = (seconds * 1000) - (Date.now() - startQuestionToShowTimer - serverTimeOffset);
                    if (timeLeft < 0) {
                        clearInterval(interval);
                        this.setState({
                            timeLeft: "0:00"
                        })
                    }
                    else {
                        let calculatedSeconds = Math.ceil(timeLeft/1000);
                        let fullTime = new Date(1000 * calculatedSeconds).toISOString().substr(11, 8)
                        let splitDate = fullTime.split(":");
                        let finalCleanTime = parseInt(splitDate[1]).toString() + ":" + splitDate[2]
                        this.setState({
                            timeLeft:finalCleanTime
                        })
                    }
                }, 100)
            }
        })
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    setUpQuestionsWithAnswerCount(userAnswers, questions){
        let finalQuestionTally = {};
        let questionIdArray = [];
        for(let questionIndex in questions){
            let question = questions[questionIndex];
            let questionId = question.id;
            finalQuestionTally[questionId] = {};
            finalQuestionTally[questionId].totalPicks = 0;
            finalQuestionTally[questionId].answers = {};
            let questionAnswers = question.answers;
            for(let answerIndex in questionAnswers){
                let answer = questionAnswers[answerIndex];
                let answerId = answer.id;
                finalQuestionTally[questionId].answers[answerId] = 0;
            }
            questionIdArray.push(questions[questionIndex].id);
        }
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let answerObject = userAnswers[answerIndex];
                for(let questionIndex in questionIdArray){
                    let questionId = questionIdArray[questionIndex];
                    if(answerObject[questionId]){
                        finalQuestionTally[questionId].totalPicks++;
                        let answerId =  answerObject[questionId];
                        finalQuestionTally[questionId].answers[answerId]++;
                    }
                }
            }
        }
        return finalQuestionTally;
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        isVisible: true
      }), 1);
    }

    handleImageErrored() {
      console.log("Image failed to load")
      this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.gameTimingRef);
        base.removeBinding(this.serverTimeOffsetRef);
        base.removeBinding(this.startQuestionToShowTimerRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentGame.questionToShow === this.state.currentGame.questionToShow - 1){
            this.setState({startAnimating:false}, ()=>{
                this.setState({startAnimating:true})
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        console.log(tenantVariables)
        const stringConstants = this.props.stringConstants || {};
        const currentGame = this.state.currentGame;
        const questions = currentGame.questions || [];
        let show_percentages = false;
        if(tenantVariables.showScoreboardPercentages === "all"){
            show_percentages = true;
        } else if(tenantVariables.showScoreboardPercentages === "parttime" && this.state.questionAnswered) {
            show_percentages = true;
        }
        let questionCount = {};
        let questionLeftText = tenantVariables.leftScoreboardQuestionSize || "44";
        questionLeftText = questionLeftText + "px";
        let currentQuestion = {};
        if(questions.length > 0){
          currentQuestion = questions[currentGame.questionToShow] || questions[0];
          questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
        }
        const backgroundImage = tenantVariables.leftScoreboardBackground || appBackgroundImage;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        const isRSLTeam = process.env.REACT_APP_FIREBASE_PROJECT_ID === "rlstrivia";
        const isAlaves = process.env.REACT_APP_FIREBASE_PROJECT_ID === "alaves-trivia";
        let clockFontSize = tenantVariables.sideClockFontSize || 75;
        if(clockFontSize){
            clockFontSize = parseInt(clockFontSize);
        }
        let timeLeft = this.state.timeLeft;
        if(isObjectEmpty(timeLeft)){
            timeLeft = ""
        } else if(currentGame.screenToShow === 2){
            timeLeft = "0:00"
        }

        return (
            <div className="grid-wrapper" style={{backgroundImage: "url(" + backgroundImage + ")", paddingTop: isRSLTeam && 275, paddingBottom: isRSLTeam && 275, marginTop: isAlaves && 125}}>
                <div className="grid-container-outer" style={{transform: "translateY(0%)"}}>
                    <div className="grid-left">
                        <div className="creator-awards-logo">
                            <img src={leftOfScoreboardImage} alt="" style={{maxHeight: "calc(100vh - 50px)", maxWidth: "100%", height: "auto", width: "auto"}}/>
                        </div>
                        {tenantVariables && tenantVariables.sideScreenTimer &&
                            <div style={{color: tenantVariables.progressBarQuestionTextColor, fontSize: clockFontSize, textAlign: "center", fontWeight: 700}}>
                                {timeLeft}
                            </div>
                        }
                    </div>
                    <div style={{marginRight: "5%", display: tenantVariables.sideScreenTimer && "flex", alignItems: tenantVariables.sideScreenTimer && "center"}}>
                        <span>
                            <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:questionLeftText, fontWeight:700, textShadow: "2px 2px #000"}}>{currentQuestion.questionText}</h1>
                            {this.state.startAnimating &&
                                <PoseGroup animateOnMount={true} delta={1} style={{margin:"auto"}}>
                                    <Trans key={233}>
                                        <AnswerItemsComponent stringConstants={stringConstants} show_percentages={show_percentages} question={currentQuestion} answers={currentQuestion.answers} questionCount={questionCount} screenToShow={currentGame.screenToShow} tenantVariables={tenantVariables} fromScoreboard={true} sideBigScreen={true} onClickAnswer={()=>console.log("click")}/>
                                    </Trans>
                                </PoseGroup>
                            }
                        </span>
                    </div>
                </div>
            </div>
          )
        }
}

export default SideBigScreen;
